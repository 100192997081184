<template>
  <workbenchbox v-loading="state.loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background"  @mouseup="store.state.MouseIndex=0,store.state.Mouseis=false" @mousemove="mousemove">
    <headNavigation ref="headNavigationRef" @signEmit="" v-model:baseInfo="state.baseInfo" @SignEmit="SignEmit"></headNavigation>
    <div class="signbox dp-f">
      <div class="w100 ptbox pt-r pt-5">
        <div class="imgbox autobox dp-fc w100 h100 fw-w " ref="autoboxRef">
          <!-- {{ state.basefilesList[state.basefilesIndex] }} -->
          <div v-for="(el, ind) in state.basefilesList[state.basefilesIndex].pages" :key="ind"
            class="mb-30 w100 dp-f jc-c" >
            <div class="pt-r mw-80">
              <img @mouseenter="onmouseover($event,ind)" :src="el.fileUrl" ref="fileimgRef"
                :style="{ width: state.fileImgW * (state.percentage / 100) + 'px' }">
                <!-- 仅签署展示 0默认 1签署 -->
              <template v-if="route.params.type == 1">
                <!-- 指定签署位置 -->
                <specifysign v-model:basefilesList="state.basefilesList" :ind="ind" :pageHeight="state.parentH" class="w100 h100 pt-r"></specifysign>
                <!-- 所有签署印章 -->
                <template v-for="(item, index) in state.sealsignatures" :key="index">
                  <!-- 直接在VueDragResize组件内继续if判断存在生成错乱问题 -->
                  <!-- 通过v-if 继续删除 直接数据删除会导致触发其他组件位置变化问题  ishide=true时为隐藏  -->
                  <VueDragResize
                    v-if="item.basefilesIndex == state.basefilesIndex && item.pageIndex == ind + 1 && !item.ishide"
                    class="sealbox" :id="item.sealId" :parentW='state.parentW' :parentH='state.parentH' :x='item.left'
                    :y='item.top' :parentLimitation="true" :isActive="true" :isResizable="false" :aspectRatio="true"
                    :w="item.width" :h="item.height" :isDraggable="item.isDraggable" @dragstop="dragstop($event, item)">
                    <div class="w100 h100 dp-fc signitembox">
                      <img :src="item.url" class="w100 h100" @click="state.nowSeal = item"/>
                      <operations v-if="state.nowSeal == item" :item="item"
                        @copyClick="unref(collapseInfoRef).copy($event)" @deteleClick="deteleClick"
                        @batchClick="batchClick" />
                    </div>
                  </VueDragResize>
                </template>
                <template v-for="(item, index) in state.sealsigns" :key="index">
                  <VueDragResize
                    v-if="item.basefilesIndex == state.basefilesIndex && item.pageIndex == ind + 1 && !item.ishide"
                    class="sealbox" :id="item.sealId" :parentW='state.parentW' :parentH='state.parentH' :x='item.left'
                    :y='item.top' :parentLimitation="true" :isActive="true" :isResizable="false" :aspectRatio="true"
                    :w="item.width" :h="item.height" :isDraggable="item.isDraggable" @dragstop="dragstop($event, item)">
                    <div class="w100 h100 dp-fc signitembox">
                      <img :src="item.url" class="w100 h100" @click="state.nowSeal = item"/>
                      <operations v-if="state.nowSeal == item" :item="item"
                        @copyClick="unref(collapseInfoRef).copy($event)" @deteleClick="deteleClick"
                        @batchClick="batchClick" />
                    </div>
                  </VueDragResize>
                </template>
                <template v-for="(item, index) in state.sealsigntimes" :key="index">
                  <VueDragResize
                    v-if="item.basefilesIndex == state.basefilesIndex && item.pageIndex == ind + 1 && !item.ishide"
                    class="sealbox" :id="item.sealId" :parentW='state.parentW' :parentH='state.parentH' :x='item.left'
                    :y='item.top' :parentLimitation="true" :isActive="true" :isResizable="false" :aspectRatio="true"
                    :w="item.width" :h="item.height" :isDraggable="item.isDraggable" @dragstop="dragstop($event, item)">
                    <div class="w100 h100 dp-fc signitembox">
                      <span class="w100 h100 dp-fc" style="line-height: 100%;" @click="state.nowSeal = item">{{ item.name
                      }}</span>
                      <operations v-if="state.nowSeal == item" :item="item"
                        @copyClick="unref(collapseInfoRef).copy($event)" @deteleClick="deteleClick"
                        @batchClick="batchClick" />
                    </div>
                  </VueDragResize>
                </template>
              </template>
            </div>
          </div>
        </div>
        <pagesNavigation class="pagesNavigation pt-a" ref="pagesNavigationRef" @Emit="changePercentage"></pagesNavigation>
      </div>
      <div class="fs-0 w-400">
        <!-- {{ state.positionDatas }} -->
        <!-- {{ state.baseInfo }} -->
        <collapseInfo ref="collapseInfoRef" v-model:baseInfo="state.baseInfo" v-model:basefilesList="state.basefilesList" />
      </div>
    </div>
    <!-- 随鼠标移动拖动组件 -->
    <div :class="['sealbox','sealbox2',store.state.Mouseis?'':'d_n','sealbox_' + (store.state.MouseType != 0 ? store.state.MouseType : (store.state.MouseData.sealType == 3 ? 0 : 3))]" :style="{left:store.state.Mouseleft+'px',top:store.state.Mousetop+'px'}">
      <div class="dp-fc w100 h100" v-show="store.state.MouseType==0||store.state.MouseType==1">
        <img class="w100 h100" :src="store.state.MouseData.url" :draggable="false"/>
      </div>
      <div class="dp-fc w100 h100" v-show="store.state.MouseType==2">
        <div>{{store.state.MouseData.NowDate}} {{store.state.MouseData.showType==2?store.state.MouseData.NowTime:''}}</div>
      </div>
    </div>
    <batch ref="batchRef" @Emit="batchSeal"></batch>
    <willSign ref="willSignRef" v-model:baseInfo="state.baseInfo" v-model:basefilesList="state.basefilesList" v-model:sealsignatures="state.sealsignatures"
      v-model:sealsigns="state.sealsigns" v-model:sealsigntimes="state.sealsigntimes" />
  </workbenchbox>
</template>

<script setup>
import { ref, unref, computed, watch, reactive, onMounted, nextTick } from "vue";
import workbenchbox from "@/components/layout/workbenchbox.vue";
import headNavigation from "./signDetails/headNavigation.vue"//头组件
import pagesNavigation from "./signDetails/pages.vue"//分页计数组件
import VueDragResize from "vue-drag-resize/src"; //拖动组件
import collapseInfo from "./signDetails/collapseInfo.vue"//折叠面板
import operations from './signDetails/drag/operations.vue'
import batch from './signDetails/dialog/batch.vue'
import specifysign from './signDetails/drag/specifysign.vue'//指定签署位置信息
import willSign from './signDetails/willingness/dialog.vue'//签署意愿
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { mul, div } from "@/utils/server/format.js"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { router_push_name } from "@/utils/server/router.js";
import qs from "qs";
const store = useStore();
const route = useRoute()
store.commit('sign/resetData')
const pagesNavigationRef = ref()//分页计算ref
const fileimgRef = ref()//文件图片ref
const autoboxRef = ref()//滚动盒子ref
const collapseInfoRef = ref()//折叠面板ref
const batchRef = ref()//批量应用
const willSignRef = ref()//签署意愿
store.state.MouseIndex=1
// 鼠标全局移动事件
const mousemove=(e)=>{
  // console.log('mousemove1111')
  store.state.MouseIndex++
  if(store.state.Mouseis==true){
    //0 签章 width: 110,height: 110,
    //1 签名 width: 134,height: 34,
    //2 日期 width: 176,height: 38,
    let xdeviation//x 偏差
    let ydeviation//y 偏差
    if(store.state.MouseType==0){
      xdeviation=55
      ydeviation=55
    }else if(store.state.MouseType==1){
      xdeviation=67
      ydeviation=17
    }else if(store.state.MouseType==2){
      xdeviation=88
      ydeviation=19
    }
    store.state.Mouseleft=(e.clientX - xdeviation)
    store.state.Mousetop=(e.clientY - ydeviation)
  }
}
// 鼠标在容器移动
const onmouseover=(e,ind)=>{
  // console.log('鼠标在容器移动',store.state.MouseIndex)
  if(store.state.MouseIndex==0){
    store.state.MousepositionData={
      data:e,
      index:ind,
      parentW:state.parentW,
      parentH:state.parentH,
    }
    // console.log('触发添加印章方法',e,ind)
  }
}
const state = reactive({
  loading: false,
  fileImgW: 1190,//文件默认宽度
  parentW: '',//拖动组件边界
  parentH: '',//拖动组件边界
  nowSeal: {},//当前操作签署数据
  filesIds: [],//当前已调取的文件id
  sealsignatures: [],//所有展示的签署数据 印章
  sealsigns: [],//所有展示的签署数据 签名
  sealsigntimes: [],//所有展示的签署数据 日期
  baseInfo: {},//基础信息
  basefilesList: [
    { pages: [] }
  ],//所有文件列表信息
  basefilesIndex: computed(() => {
    return store.state.sign.basefilesIndex
  }),//当前展示文件下标
  percentage: 100,//当前百分比
  //当前签署人所有指定签署位置 0 签章，1 签名，2 日期，3 骑缝章
  sealpositionDatas: computed(() => {
    return store.state.sign.sealpositionDatas//签章
  }),
  signaturepositionDatas: computed(() => {
    return store.state.sign.signaturepositionDatas//签名
  }),
  datepositionDatas: computed(() => {
    return store.state.sign.datepositionDatas//日期
  }),
});


// 签署点击返回
const SignEmit=()=>{
  // 判断所有指定的签署位置是否已全部签署
  for(let i=0;i<state.sealpositionDatas.length;i++){
    if(!state.sealpositionDatas[i].nowSignData){
      handleMessage('尚未完成签章位置指定签署')
      return
    }
  }
  for(let i=0;i<state.signaturepositionDatas.length;i++){
    if(!state.signaturepositionDatas[i].nowSignData){
      handleMessage('尚未完成个人签名位置指定签署')
      return
    }
  }
  for(let i=0;i<state.datepositionDatas.length;i++){
    if(!state.datepositionDatas[i].nowSignData){
      handleMessage('尚未完成日期位置指定签署')
      return
    }
  }
  if (!unref(collapseInfoRef).checkAddSignData()) {
    return
  }
  unref(willSignRef).show()
}
// 批量应用印章
const batchSeal = (data) => {
  data.forEach(item => {
    if (store.state.sign.currentPage != item.pageIndex)
      unref(collapseInfoRef).copyExecute(state.nowSeal, item.pageIndex)
  })
}
// 批量应用
const batchClick = (el) => {
  unref(batchRef).show(state.baseInfo, state.basefilesList[state.basefilesIndex])
}
// 删除点击
const deteleClick = (el) => {
  store.state.MouseIndex=1
  // console.log('删除点击',el)
  unref(collapseInfoRef).detele(el)
}
// 重新获取当前页码
const getNowPageIndex = (() => {
  nextTick(() => {
    unref(pagesNavigationRef).setPages(parseInt(unref(autoboxRef).scrollTop / (state.parentH + 30)) + 1)
  })
})
// 重新设置拖动组件边界
const resetParentWH = (() => {
  nextTick(() => {
    state.parentW = unref(fileimgRef)[0].offsetWidth
    state.parentH = unref(fileimgRef)[0].offsetHeight
  })
})
// 获取页面大小
watch(() => fileimgRef.value,
  (value, oldValue) => {
    setTimeout(() => {
      resetParentWH();
    }, 200)
  },
  // { immediate: true } //初始化时立即执行回调函数
);
// 百分比改变方法
const changePercentage = (() => {
  resetParentWH()
})
// 拖拽结束事件
const dragstop = (value, item) => {
  console.log('拖拽结束事件', value, state.parentW, state.parentH, item)
  item.top = value.top
  item.left = value.left

  item.signatureY = mul(div((value.top + item.height), state.parentH), 100).toFixed(3)
  item.signatureX = mul(div(value.left, state.parentW), 100).toFixed(3)
  if (item.signatureY > 100) {
    item.signatureY = 100
  }
  console.log("(x,y)", item.signatureX, item.signatureY)
}
// 获取当前签署人所有指定签署位置
const getpositionDatas = (id) => {
  // store.commit('sign/resetpositionData')
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/findPosition',
    data: qs.stringify({
      'file_id':id?id:state.basefilesList[state.basefilesIndex].id,
      'contract_id': route.params.id
    })
  }).then((res) => {
    console.log('获取所有指定签署位置数据', res)
    store.commit('sign/setpositionDatas', res.data.signaturePosition)
    // console.log("set check time")
    store.commit('sign/setcheckTime',new Date())
    // state.positionDatas = res.datsealpositionDatas
  })
}
// 获取签署文件列表数据
const getfilesData = ((uploadType) => {
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/findSign',
    data: qs.stringify({
      'contract_id': route.params.id,
      'type':route.params.type==1?0:1,//可签署 0 不可签署 1
      'uploadType':uploadType,//0 其他 1 模板
    })
  }).then((res) => {
    state.basefilesList = res.data
    // state.filesIds.push(res.data[0].id)
    // 设置份数
    unref(pagesNavigationRef).setCopies(res.data.length)
    // 重新设置页码大小
    unref(pagesNavigationRef).setTotalPages(state.basefilesList[state.basefilesIndex].pages.length)
    // getpositionDatas()
    // 获取该合同所有待签署位置数据
    res.data.forEach(item=>{
      state.filesIds.push(item.id)
      getpositionDatas(item.id)
    })
  })
})

// 文件列表切换
watch(() => state.basefilesIndex, (value, oldValue) => {
  // 重新设置页码大小
  unref(pagesNavigationRef).setTotalPages(state.basefilesList[state.basefilesIndex].pages.length)
  // 未查询到当前文件添加位置数据
  if (!state.filesIds.includes(state.basefilesList[state.basefilesIndex].id)) {
    state.filesIds.push(state.basefilesList[state.basefilesIndex].id)
    getpositionDatas()
  }
},)
// 获取详情数据
const getFindData = (() => {
  let json = [
    { column: "id", type: 'eq', value: route.params.id }
  ];
  state.loading = true
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/find',
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
    state.loading = false
    state.baseInfo = res.data
    getfilesData(res.data.uploadType)
    console.log('获取详情数据', res)
  }).catch(() => {
    state.loading = false
  })
})
getFindData()
const getFindBoolean = (() => {
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/findBoolean',
    data: qs.stringify({
      contract_id: route.params.id
    }),
  }).then((res) => {
    console.log('findBoolean',res)
    if(res.data==false){
      handleMessage('该用户无法进行签署')
      router_push_name('operation_worktable',undefined,false,false)
    }
  })
})
getFindBoolean()
onMounted(() => {
  nextTick(() => {
    window.addEventListener('scroll', getNowPageIndex, true);
  })
  state.sealsignatures = computed(() => {
    // console.log('signatures: ', unref(collapseInfoRef).signatures)
    return unref(collapseInfoRef).signatures
  })
  state.sealsigns = computed(() => {
    // console.log(unref(collapseInfoRef).signs,'22222222')
    return unref(collapseInfoRef).signs
  })
  state.sealsigntimes = computed(() => {
    return unref(collapseInfoRef).signtimes
  })
  state.percentage = computed(() => {
    return unref(pagesNavigationRef).state.percentage
  })
})
</script>

<style lang="scss" scoped>
@import "@/styles/general/element.scss"; //element 样式重置

.signbox {
  width: 100%;
  height: calc(100vh - 100px - 88px);

  // background-color: rebeccapurple;
  .signitembox {
    position: relative;
  }
}

.mw-80 {
  max-width: 80%;

  img {
    max-width: 100%;
  }
}

.pagesNavigation {
  bottom: 0;
  left: 0;
}

.ptbox {
  max-height: calc(100% - 5px);
}

::v-deep .sealbox {
  border: 1px solid #FF0000 !important;
  background: rgba(255, 0, 0, 0.1);
}
::v-deep .sealbox2{
  position: absolute;
  box-sizing: content-box;
  // width: 100px;
  // height: 100px;
  z-index: 6000!important;
}
//0 签章 width: 110,height: 110,
  //1 签名 width: 134,height: 34,
  //2 日期 width: 176,height: 38,
::v-deep .sealbox_0{
  width: 155px;
  height: 155px;
}
::v-deep .sealbox_1{
  width: 134px;
  height: 34px;
}
::v-deep .sealbox_2{
  width: 176px;
  height: 38px;
}
::v-deep .sealbox_3{
  width: 70px;
  height: 70px;
}
.d_n{
  display: none;
}
</style>